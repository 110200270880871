import { Button, Flex, Input, Space, Typography, Upload, Alert, Modal } from "antd";
import { CameraOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { BASE_URL } from "../constant";
import { rowStyles } from "../assets/styles/custom_styles";
import { useRef, useState } from "react";
import MapContainer from "./MapContainer";
const { Text } = Typography;
const { TextArea } = Input;

export const ConfirmService = ({ 
    service, 
    confirm, 
    post, 
    appStatus, 
    addressSetter 
}) => {

    const handleChange = (info) => {
        let newFileList = [...info.fileList];
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        newFileList = newFileList.slice(-2);
        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file.response ?? file
        });

        confirm(null, newFileList, service.description)
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const mapRef = useRef();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {

        setIsModalOpen(false);
        // get address from map
        const {
            set,
            address,
            lat,
            lng
        } = mapRef.current?.getAddressSelection();

        // set address
        if (set) addressSetter(address, lat, lng);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const props = {
        action: `${BASE_URL}/resources/image`,
        onChange: handleChange,
        multiple: true,
    };

    return (
        <>
            <Space direction="vertical" style={rowStyles}>
                <Text strong>Add Media Filters</Text>
                <Upload {...props} fileList={service.files}>
                    <Button
                        type="primary"
                        icon={<CameraOutlined />}
                        size="large"
                        style={{ marginTop: "0.15rem" }}
                        ghost
                        block
                    >
                        Click to Upload
                    </Button>
                </Upload>
                <Text>
                    By uploading any data, information, templates, content, code, video, images or other materials or information
                    of any type, you agree, and the administrator of your account has agreed, to our
                    Terms & Conditions and Privacy Policy.
                </Text>
                { /* address */}
                <Text strong>Location</Text>
                <Flex gap={16} style={{ marginBottom: "0.5rem" }}>
                    <Input
                        placeholder="Add your location"
                        size="large"
                        value={service?.address}
                        readOnly
                    />
                    <Button
                        type="primary"
                        icon={<EnvironmentOutlined />}
                        size="large"
                        onClick={showModal}
                        style={{ width: "45px" }}
                    />
                </Flex>
                { /* description */}
                <Text strong>Add details about the service to report.</Text>
                <TextArea
                    type="textarea"
                    placeholder="Description"
                    autoSize={{
                        minRows: 6,
                        maxRows: 6
                    }}
                    size="large"
                    title="Description"
                    name="description"
                    onChange={(e) => {
                        confirm(null, service.files, e.target.value)
                        delete service.errors?.description
                    }}
                    style={{ width: "100%" }}
                />
                {
                    service.errors?.description && (
                        <span style={{ color: 'red' }}>{service.errors?.description}</span>
                    )
                }
                {
                    appStatus.status === "error" &&
                    <Alert
                        message="Sorry! Something went wrong. Please try again later."
                        type="error"
                        closable
                        style={{ marginTop: "0.5rem" }}
                    />
                }
                <Flex gap={15} wrap="wrap" style={actionStyles} justify="center">
                    <Button type="primary" size="large" color="success" onClick={post}>Confirm</Button>
                    <Button color="lightgrey" size="large" onClick={() => confirm(false)}>Cancel</Button>
                </Flex>

            </Space>
            <Modal title="Location" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <MapContainer ref={mapRef} />
            </Modal>
        </>
    )
}

const actionStyles = {
    marginTop: "0.5rem"
}