import { Space } from "antd";
import { customColors, rowStyles } from "../assets/styles/custom_styles";

export const Header = ({ title }) => {
    return (
        <Space
            direction="vertical"
            align="center"
            style={{
                ...rowStyles,
                borderTop: "5px solid " + customColors.primary
            }}
        >
            <img
                width={300}
                alt={title}
                src="./images/logo.png"
            />
        </Space>
    )
}