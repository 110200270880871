import { Flex, Typography } from "antd";
import { Header } from "./components/Header";
import { useEffect, useState } from "react";
import { BASE_URL } from "./constant";
import { ConfirmService } from "./components/ConfirmService";
import { ThankYou } from "./components/ThankYou";
import { SubHeader } from "./components/SubHeader";
import SelectServices from "./components/SelectServices";
import { bodyStyles, containerStyles } from "./assets/styles/custom_styles";
import Footer from "./components/Footer";
import { API_KEY } from "./map.config";

const getServerData = async (code) => {
  const response = await fetch(`${BASE_URL}/bank/service/${code}`);
  return await response.json();
}

const postService = async (code, data) => {
  const response = await fetch(`${BASE_URL}/bank/service/${code}/cleaning`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  return await response.json();
}

// handle geo location
const getLocationData = (setter) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      // success
      (position) => {
        getAddress(position.coords.latitude, position.coords.longitude, setter)
      },
      () => {
        console.log("Unable to retrieve your location");
      });
  } else {
    console.log("Geolocation not supported");
  }
}

// get address from location
const getAddress = async (lat, long, setter) => {
  try {
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${API_KEY}`
    );

    const res_json = await res.json();
    const { formatted_address, geometry } = res_json.results[0] || {};
    setter(
      formatted_address,
      geometry?.location.lat,
      geometry?.location.lng
    );

  } catch (error) {
    console.error(error)
  }
}

// let clearTimmer = -1;

function App() {
  const url = new URL(window.location.href); // Get current URL
  const serviceCode = url.searchParams.get('site');
  const [service, setService] = useState();
  const [confirmService, setConfirmService] = useState({
    service: null,
    files: [],
    address: null,
    lat: null,
    lng: null,
    description: "",
    check: 1,
    errors: {}
  });

  const [appStatus, setAppStatus] = useState({
    status: null,
    message: null
  })

  const conService = (check, files = null, description = null) => {
    if (check === null) {
      setConfirmService(pre => {
        return {
          ...pre,
          files,
          description
        }
      })
      return;
    }
    setConfirmService(pre => {
      return {
        ...pre,
        check: check ? pre.check + 1 : pre.check - 1
      }
    })
  }

  //set address
  const setAddress = (address = null, lat = null, lng = null) => {
    setConfirmService(
      prev => ({
        ...prev,
        address,
        lat,
        lng
      })
    )
  }


  // handle service change
  const handleServiceChange = (service) => {
    setConfirmService(pre => {
      return {
        service,
        check: (pre.check + 1)
      }
    });
    getLocationData(setAddress);
  }


  useEffect(() => {
    getServerData(serviceCode).then((data) => {
      setService(data)
    })
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (clearTimmer === 0) {
  //       resetState()
  //     } else if (clearTimmer > 0) {
  //       clearTimmer -= 1;
  //     }
  //   }, 1000)
  //   return () => clearInterval(intervalId);
  // })

  // useEffect(() => {
  //   if (clearTimmer === null) {
  //     clearTimmer = -1
  //   } else {
  //     clearTimmer = 30
  //   }
  // }, [confirmService]);

  const postServiceRequest = async () => {
    const res = await postService(serviceCode, {
      site: serviceCode,
      images: confirmService.files,
      description: confirmService.description,
      service_id: confirmService.service.id,
      address: confirmService.address,
      lat: confirmService.lat,
      lng: confirmService.lng,
    }).then(e => {
      if (e.statusCode == 400) {
        setConfirmService(p => {
          return {
            ...p,
            errors: e.errors
          }
        })
      } else {
        setAppStatus(e);
      }
    });

  }

  if (!!service && !service.siteTitle) {
    return <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical justify="center" align="center" style={{ height: 300 }}>
        <Typography.Title level={3} style={{ color: "red" }}>This service does not exist</Typography.Title>
      </Flex>
    </Flex>
  }

  return (
    <div style={bodyStyles}>
      <Flex gap="middle" vertical style={containerStyles}>
        <Header
          title={service?.siteTitle ?? "Loading ..."}
          lastTime={service?.lastTime}
        />
        <SubHeader
          title={service?.siteTitle ?? "Loading..."}
        />
        {appStatus.status !== "success" &&
          <>
            <SelectServices
              services={service?.services}
              service={confirmService?.service}
              confirm={handleServiceChange}
              state={confirmService.check}
            />
            {!!confirmService.service &&
              <ConfirmService
                service={confirmService}
                confirm={conService}
                post={postServiceRequest}
                appStatus
                addressSetter={setAddress}
              />
            }
          </>
        }

        {appStatus.status === "success" &&
          <ThankYou title={appStatus.message} />
        }
        <Footer />
      </Flex>
    </div>
  );
}

export default App;